import React, {Component} from 'react';

class Music extends Component {
    render() {
        return (
            <section id="music">
                <div className="music-section-container">
                    <div className="primary_subheading-container">
                        <h1 className="primary-subheading">Music</h1>
                    </div>
                </div>
                <iframe title="Sendero"
                        src="https://open.spotify.com/embed/album/0elK4sCiKSNQaOagqxyj5G?utm_source=generator&theme=0"
                        width="100%" height="352" frameBorder="0" allowFullScreen=""
                        allow="autoplay; clipboard-write; fullscreen; picture-in-picture;"
                        loading="lazy"
                >

                </iframe>
                <iframe title="Funshine"
                        src="https://open.spotify.com/embed/album/0qugWpJvaOWOqNJziwudNO?utm_source=generator&theme=0"
                        width="100%" height="352" frameBorder="0" allowFullScreen=""
                        allow="autoplay; clipboard-write; fullscreen; picture-in-picture;"
                        loading="lazy">

                </iframe>


            </section>
        );
    }
}

export default Music;