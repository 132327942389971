import React from "react";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Grid, IconButton, Typography} from "@mui/material";
import Box from "@mui/material/Box";

function Copyright() {
    return (
        <Typography variant="body2" color="white">
            {'Copyright © jazzyjpianoman.com '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const Footer = () => {
    return (

            <section id="footer">
                <Box component="footer"
                     sx={{
                         py: 3,
                         px: 2,
                         mt: 'auto',
                         bgcolor: '#262626',
                         height: '200px',
                         width: '100vw'
                     }}>
                    <Grid  container
                           spacing={0}
                           direction="column"
                           alignItems="center"
                           justifyContent="center">
                        <Typography variant="body1" color="#f6f6f6">Follow Me on Social Media</Typography>
                            <div>
                                <a href="https://www.youtube.com/@justinpfeifer" rel="noopener" color="inherit" target="_blank">

                                    <IconButton sx={{ color: "#f6f6f6", '&:hover': {color: "#FF0000"} }} size='large'>
                                        <BsYoutube>
                                        </BsYoutube>
                                    </IconButton>
                                </a>
                                <a href="https://www.facebook.com/justin.pfeifer.5686/" rel="noopener" color="inherit" target="_blank">
                                    <IconButton sx={{ color: "#f6f6f6", '&:hover': {color: "#4267B2" } }} size='large'>
                                        <FaFacebookF />
                                    </IconButton>
                                </a>
                            </div>



                        <Copyright />
                    </Grid>

                </Box>


            </section>



    );
};

export default Footer;