import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/profile-picture.png";

const About = () => {
    return (
        <section id="about">
            <div className="about-section-container">
                <div className="about-background-image-container">
                    <img src={AboutBackground} alt="" />
                </div>
                <div className="about-section-image-container">
                    <img src={AboutBackgroundImage} alt="" />
                </div>
                <div className="about-section-text-container">
                    <p className="primary-subheading">Biography</p>
                    <p className="primary-text">
                        <strong>Justin Pfeifer</strong> is a jazz pianist, organist, composer and arranger. He has performed professionally for many years. He is diverse in many musical idioms which include: jazz, funk, blues, and rock. Growing up in the Roaring Fork Valley in Colorado led to many great musical opportunities.
                        One of which led to receiving instruction from jazz trumpeter, pianist and music educator Tim Fox.
                    </p>
                    <p className="primary-text">
                        <strong>Tim</strong> introduced Justin to Miles Davis, John Coltrane, McCoy Tyner, Bill Evans, Chick Corea all of whom became musical influences. Justin was also fortunate to study with grammy nominated Hammond B3 jazz organist Pat Bianchi and performed with Lab Band One at the University of Northern Colorado.
                    </p>
                    <p className="primary-text">
                        <strong>Justin</strong> has toured internationally and throughout the western United States. He's performed with the MST Jazz Trio, Jes Grew, TJAAR, Let Them Roar, Lipbone Redding, Jeff Solon, The Rico Blues Project and many more. He is a freelance musician and currently resides in Southwest Utah.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;